import React, { useEffect } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { seminargoColor } from 'theme';

interface TabsPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

interface TabsProps {
  headers: string[] | React.ReactNode[];
  headerBadges?: any[];
  icons?: (React.ReactElement | undefined)[];
  disabled?: boolean[];
  hidden?: boolean[];
  slugs?: string[];
  tabs: React.ReactNode[];
  initialSelected?: number | null;
}
interface TabsControlledProps {
  headers: string[];
  headerBadges?: string[];
  icons?: (React.ReactElement | undefined)[];
  disabled?: boolean[];
  hidden?: boolean[];
  tabs: React.ReactNode[];
  selected: number;
  handleChange: (selected: number) => void;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabsPanel(props: TabsPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const badge = (content: any) => <div style={{
  display: 'flex',
  justifyItems: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  height: '1.5em',
  width: '1.5em',
  borderRadius: '100%',
  backgroundColor: seminargoColor,
  color: 'white',
  fontSize: '0.8em',
  marginLeft: '0.5em',
  verticalAlign: 'middle',

}}>{content}</div>

export function CustomTabsControlled(props: TabsControlledProps) {
  const { headerBadges, headers, disabled, icons, tabs, selected, hidden, handleChange } = props;

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selected} onChange={(event: React.SyntheticEvent, newValue: number) => handleChange(newValue)}>
          {headers.map((h, index) =>
            (!hidden || !hidden[index]) &&
              <Tab
                key={index}
                value={index}
                label={headerBadges && headerBadges[index] !== null ? <>{h} {badge(headerBadges[index])}</> : h}
                {...a11yProps(index)}
                disabled={disabled && disabled[index]}
                icon={icons && icons[index]}
                iconPosition="start"
              />
          )}
        </Tabs>
      </Box>
      {tabs.map((c, index) =>
        disabled && disabled[index] ? (
          <React.Fragment key={index} />
        ) : (
          <CustomTabsPanel key={index} value={selected} index={index}>
            {c}
          </CustomTabsPanel>
        ),
      )}
    </>
  );
}

export default function CustomTabs(props: TabsProps) {
  const { headerBadges, headers, disabled, icons, tabs, hidden, initialSelected, slugs } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const _getValueFromLocation = () => {
    const foundTab = (slugs || []).findIndex((slug) => location.pathname.includes(slug));
    if (foundTab >= 0) return foundTab
    return initialSelected || 0
  }

  const [value, setValue] = React.useState(_getValueFromLocation());
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const newValue = _getValueFromLocation()
    if (newValue !== value) setValue(newValue)
  }, [location.pathname]);

  useEffect(() => {
    if (slugs && slugs[value]) {
      navigate(slugs[value]);
    }
  }, [value]);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          {headers.map((h, index) =>
            (!hidden || !hidden[index]) &&
              <Tab
                key={index}
                value={index}
                label={headerBadges && headerBadges[index] !== null ? <>{h} {badge(headerBadges[index])}</> : h}
                {...a11yProps(index)}
                disabled={disabled && disabled[index]}
                icon={icons && icons[index]}
                iconPosition="start"
              />
          )}
        </Tabs>
      </Box>
      {tabs.map((c, index) =>
        disabled && disabled[index] ? (
          <React.Fragment key={index} />
        ) : (
          <CustomTabsPanel key={index} value={value} index={index}>
            {c}
          </CustomTabsPanel>
        ),
      )}
    </>
  );
}

import { createTheme, PaletteColorOptions } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { deDE } from '@mui/material/locale';
import { colors } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    disabled: PaletteColorOptions;
  }
  interface PaletteOptions {
    disabled: PaletteColorOptions;
  }
}

export const seminargoColor = '#ac2a6e'

// A custom theme for this app
const theme = createTheme(
  {
    components: {
      MuiIconButton: {
        defaultProps: {
          size: 'small'
        },
        styleOverrides: {
          root: {
            '& svg': {
              fontSize: '1.25rem',
            },
          }
        }
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            padding: '6px 16px',
          }
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '& svg': {
              height: '0.8em',
              width: '0.8em',
            },
          },
        }
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            '& svg': {
              height: '0.8em',
              width: '0.8em',
            },
          },
        }
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            marginLeft: '10px',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            fontSize: '0.875rem',
            padding: '12px 12px',
          },
          root: {
            padding: '0',
          }
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          outlined: {
            fontSize: '0.805rem',
            marginTop: '-2px'
          },
          shrink: {
            marginTop: '1px !important',
          }
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: '0.875rem',
            minHeight: 'auto',
            padding: '12px'
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: 'auto',
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            border: '1px solid #eee',
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            border: '1px solid #e0e0e0',
            boxShadow: 'none',
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            fontSize: '0.875rem',
            paddingLeft: '56px',
            paddingTop: '20px',
            paddingBottom: '8px',
            lineHeight: '20px',
          }
        }
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: '36px',
            '& svg': {
              fontSize: '1.25rem',
            },
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontSize: '0.875rem',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '12px',
            fontSize: '0.875rem',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            fontSize: '0.875rem',
            ':hover': {
              boxShadow: 'none',
            },
          },
          containedSecondary: {
            border: '1px solid ' + seminargoColor,
            color: seminargoColor,
            '&:disabled': {
              border: '1px solid #999',
            },
            '&:hover': {
              backgroundColor: seminargoColor + '11'
            },
          },
        },
      },
    },
    palette: {
      primary: {
        main: seminargoColor
      },
      secondary: {
        main: '#fff',
      },
      disabled: {
        main: '#999',
      },
      error: {
        main: red.A400,
      },
    },
  },
  deDE,
);

export default theme;

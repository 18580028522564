import _ from 'lodash';
import moment from 'moment';
import { EPricing_FacilityLookupCode, EPricing_FacilityPayment, EPricing_ProductPayment, EWL_OfferRoomOccupancy, ICalcLineItem, ICalcOptions, ICalcPriceOutput, IQuickPriceDay, IQuickPriceInput, IServiceType, mergeLineItem, mergeTaxes, NO_PRICE, QuickPriceCalculatorBase, QuickPriceError, sumTotalPrices } from './quickprice';

export interface IQuickPriceInputWidget extends IQuickPriceInput<IQuickPriceDayWidget> {
  prevdayGuests: number;
  serviceTypeSku?: string | null;
}

export interface IQuickPriceDayWidget extends IQuickPriceDay {
  occupancy: string[];
  overnightGuests: number;
}

export interface ICalcPriceOutputWidget extends ICalcPriceOutput {
  serviceType: IServiceType;
};

export type QuickPriceInputTypeWidget = IQuickPriceInputWidget
export type CalcPriceOutputTypeWidget = ICalcPriceOutputWidget;
export type CalcLineItemTypeWidget = ICalcLineItem;
export type QuickPriceCalculatorTypeWidget = QuickPriceCalculatorWidget;

export class QuickPriceCalculatorWidget extends QuickPriceCalculatorBase<IQuickPriceDayWidget, IQuickPriceInputWidget, ICalcOptions, ICalcPriceOutputWidget> {

  public calculatePrice(input: IQuickPriceInputWidget, calcOptions?: ICalcOptions): ICalcPriceOutputWidget {
    const startMom = moment(input.startDate);
    const endMom = moment(input.endDate);

    const days = endMom.diff(startMom, 'days') + 1;
    for (let dayNum = 0; dayNum < days; dayNum++) {
      if (input.days.findIndex(d => d.day === dayNum) < 0) {
        throw new QuickPriceError(`No selection found for day ${dayNum}`, {
          extensions: { code: 'BAD_INPUT' },
        });
      }
    }

    const initialPriceList = this.getPriceList({
      date: startMom.toDate()
    });

    const allProductsDb = this.allProductsDb!;
    const allFacilitiesDb = this.allFacilitiesDb!;
    const allBundlesDb = this.allBundlesDb!;
    const allServiceTypesDb = this.allServiceTypesDb!;

    const serviceType = allServiceTypesDb.find(st => st.sku === input.serviceTypeSku)!;

    //TODO check minVisitors/maxVisitors etc

    const result: ICalcPriceOutputWidget = {
      priceList: initialPriceList,
      serviceType: serviceType!,
      hotel: this.hotel!,
      totalPriceGross: 0.0,
      totalPriceNet: 0.0,
      totalTaxes: 0.0,
      taxes: [],
      lineItems: [],
    };

    const _isFullDay = (day: IQuickPriceDayWidget) => !!day.occupancy.find(o => o === EWL_OfferRoomOccupancy.FULLDAY);

    const _filterServiceTypeProductsByPayment = (recurring: string) =>
      serviceType.products.filter(sp => {
        if (sp.product) {
          const p = allProductsDb.find(pd => pd.id === sp.product!.id);
          return p && p.recurring === recurring;
        }
        if (sp.bundle) {
          const b = allBundlesDb.find(pd => pd.id === sp.bundle!.id);
          return b && b.recurring === recurring;
        }
      });

    if (calcOptions?.addonsOnly === false || calcOptions?.addonsOnly === undefined) {
      const allDaysGuestsCount = input.days.reduce(
        (acc, day) => {
          acc += day.totalGuests;
          return acc;
        },
        0,
      );

      const recurringProducts = [..._filterServiceTypeProductsByPayment(EPricing_ProductPayment.BYGUESTANDDAY), ..._filterServiceTypeProductsByPayment(EPricing_ProductPayment.BYROOMANDDAY)];

      for (let dayNum = 0; dayNum < days; dayNum++) {
        const dayMom = moment(startMom).add(dayNum, 'days');
        const day = input.days.find(d => d.day === dayNum)!;
        const isFullDay = _isFullDay(day);

        const priceList = this.getPriceList({ date: dayMom.toDate() });

        const counts = {
          dayVisitorGuests: 0,
          overnightGuests: 0,
          roomGuests: 0,
          departureGuests: 0,
          nextdayGuests: 0,
        };
        if (dayNum === 0 && days === 1) {
          if (day.occupancy.length === 0) {
            counts.nextdayGuests = day.overnightGuests;
          } else {
            counts.dayVisitorGuests = day.totalGuests;
            counts.nextdayGuests = day.overnightGuests;
          }
        } else if (dayNum === days - 1) {
          counts.nextdayGuests = day.overnightGuests;

          if (day.occupancy.length > 0) {
            const prevDay = input.days.find(d => d.day === dayNum - 1)!;
            counts.departureGuests = Math.min(day.totalGuests, Math.max(0, prevDay.overnightGuests - day.overnightGuests));
            counts.dayVisitorGuests = Math.max(0, day.totalGuests - counts.departureGuests);
          }
        } else {
          if (day.occupancy.length === 0) {
            counts.roomGuests = day.overnightGuests;
          } else if (day.totalGuests < day.overnightGuests) {
            counts.overnightGuests = day.totalGuests;
            counts.roomGuests = day.overnightGuests - day.totalGuests;
          } else {
            counts.dayVisitorGuests = day.totalGuests - day.overnightGuests;
            counts.overnightGuests = day.overnightGuests;
          }
        }

        if (counts.dayVisitorGuests > 0) {
          const dayVisitorProducts = recurringProducts.filter(p => (isFullDay ? p.isForDayVisitor : p.isForSemidayVisitor));
          for (const p of dayVisitorProducts) {
            if (p.bundle) {
              const lineItem = this.getBundleLineItem(dayNum, {
                priceListId: priceList.id,
                bundleId: p.bundle.id,
                guestCount: day.totalGuests,
                itemCount: counts.dayVisitorGuests,
                date: dayMom.toDate()
              }, null, calcOptions);
              if (lineItem) mergeLineItem(result, lineItem);
            }
            if (p.product) {
              const lineItem = this.getProductLineItem(dayNum, {
                priceListId: priceList.id,
                productId: p.product.id,
                guestCount: day.totalGuests,
                itemCount: counts.dayVisitorGuests,
                date: dayMom.toDate(),
              }, null, calcOptions);
              if (lineItem) mergeLineItem(result, lineItem);
            }
          }
        }
        if (counts.departureGuests > 0) {
          const departureProduct = recurringProducts.filter(p => (isFullDay ? p.isForDeparture : p.isForSemidayDeparture));
          for (const p of departureProduct) {
            if (p.bundle) {
              const lineItem = this.getBundleLineItem(dayNum, {
                priceListId: priceList.id,
                bundleId: p.bundle.id,
                guestCount: day.totalGuests,
                itemCount: counts.departureGuests,
                date: dayMom.toDate(),
              }, null, calcOptions);
              if (lineItem) mergeLineItem(result, lineItem);
            }
            if (p.product) {
              const lineItem = this.getProductLineItem(dayNum, {
                priceListId: priceList.id,
                productId: p.product.id,
                guestCount: day.totalGuests,
                itemCount: counts.departureGuests,
                date: dayMom.toDate(),
              }, null, calcOptions);
              if (lineItem) mergeLineItem(result, lineItem);
            }
          }
        }
        if (counts.overnightGuests > 0) {
          const overnightProducts = recurringProducts.filter(p => (isFullDay ? p.isForOvernight : p.isForSemidayOvernight));
          for (const p of overnightProducts) {
            if (p.bundle) {
              const lineItem = this.getBundleLineItem(dayNum, {
                priceListId: priceList.id,
                bundleId: p.bundle.id,
                guestCount: day.totalGuests,
                itemCount: counts.overnightGuests,
                date: dayMom.toDate(),
              }, null, calcOptions);
              if (lineItem) mergeLineItem(result, lineItem);
            }
            if (p.product) {
              const lineItem = this.getProductLineItem(dayNum, {
                priceListId: priceList.id,
                productId: p.product.id,
                guestCount: day.totalGuests,
                itemCount: counts.overnightGuests,
                date: dayMom.toDate(),
              }, null, calcOptions);
              if (lineItem) mergeLineItem(result, lineItem);
            }
          }
        }
        if (counts.roomGuests > 0) {
          const roomProducts = recurringProducts.filter(p => p.isForRoom);
          for (const p of roomProducts) {
            if (p.bundle) {
              const lineItem = this.getBundleLineItem(dayNum, {
                priceListId: priceList.id,
                bundleId: p.bundle.id,
                guestCount: day.totalGuests,
                itemCount: counts.roomGuests,
                date: dayMom.toDate(),
              }, null, calcOptions);
              if (lineItem) mergeLineItem(result, lineItem);
            }
            if (p.product) {
              const lineItem = this.getProductLineItem(dayNum, {
                priceListId: priceList.id,
                productId: p.product.id,
                guestCount: day.totalGuests,
                itemCount: counts.roomGuests,
                date: dayMom.toDate(),
              }, null, calcOptions);
              if (lineItem) mergeLineItem(result, lineItem);
            }
          }
        }
        if (counts.nextdayGuests > 0) {
          const nextdayProducts = recurringProducts.filter(p => p.isForNextday);
          for (const p of nextdayProducts) {
            if (p.bundle) {
              const lineItem = this.getBundleLineItem(dayNum, {
                priceListId: priceList.id,
                bundleId: p.bundle.id,
                guestCount: day.totalGuests,
                itemCount: counts.nextdayGuests,
                date: dayMom.toDate(),
              }, null, calcOptions);
              if (lineItem) mergeLineItem(result, lineItem);
            }
            if (p.product) {
              const lineItem = this.getProductLineItem(dayNum, {
                priceListId: priceList.id,
                productId: p.product.id,
                guestCount: day.totalGuests,
                itemCount: counts.nextdayGuests,
                date: dayMom.toDate(),
              }, null, calcOptions);
              if (lineItem) mergeLineItem(result, lineItem);
            }
          }
        }
      }

      if (allDaysGuestsCount > 0) {
        const byCountProducts = _filterServiceTypeProductsByPayment(EPricing_ProductPayment.BYCOUNT);

        for (const p of byCountProducts) {
          const firstDay = input.days.find(d => d.day === 0)!;
          const priceList = this.getPriceList({ date: startMom.toDate() });

          if (p.bundle) {
            const lineItem = this.getBundleLineItem(0, {
              priceListId: priceList.id,
              bundleId: p.bundle.id,
              guestCount: firstDay.totalGuests,
              itemCount: 1,
              date: startMom.toDate(),
            }, null, calcOptions);
            if (lineItem) mergeLineItem(result, lineItem);
          }
          if (p.product) {
            const lineItem = this.getProductLineItem(0, {
              priceListId: priceList.id,
              productId: p.product.id,
              guestCount: firstDay.totalGuests,
              itemCount: 1,
              date: startMom.toDate(),
            }, null, calcOptions);
            if (lineItem) mergeLineItem(result, lineItem);
          }
        }
      }

      const byGuestProducts = [..._filterServiceTypeProductsByPayment(EPricing_ProductPayment.BYGUEST), ..._filterServiceTypeProductsByPayment(EPricing_ProductPayment.BYROOM)];

      for (const p of byGuestProducts) {
        const firstDay = input.days.find(d => d.day === 0)!;
        const priceList = this.getPriceList({ date: startMom.toDate() });

        if (p.bundle) {
          const lineItem = this.getBundleLineItem(0, {
            priceListId: priceList.id,
            bundleId: p.bundle.id,
            guestCount: firstDay.totalGuests,
            itemCount: firstDay.totalGuests,
            date: startMom.toDate(),
          }, null, calcOptions);
          if (lineItem) mergeLineItem(result, lineItem);
        }
        if (p.product) {
          const lineItem = this.getProductLineItem(0, {
            priceListId: priceList.id,
            productId: p.product.id,
            guestCount: firstDay.totalGuests,
            itemCount: firstDay.totalGuests,
            date: startMom.toDate(),
          }, null, calcOptions);
          if (lineItem) mergeLineItem(result, lineItem);
        }
      }

      if (input.prevdayGuests > 0) {
        const prevDay = moment(startMom).add(-1, 'days').toDate();
        const priceList = this.getPriceList({ date: prevDay });

        const prevdayProducts = recurringProducts.filter(p => p.isForPrevday);
        for (const p of prevdayProducts) {
          if (p.bundle) {
            const lineItem = this.getBundleLineItem(-1, {
              priceListId: priceList.id,
              bundleId: p.bundle.id,
              guestCount: input.prevdayGuests,
              itemCount: input.prevdayGuests,
              date: prevDay,
            }, null, calcOptions);
            if (lineItem) mergeLineItem(result, lineItem);
          }
          if (p.product) {
            const lineItem = this.getProductLineItem(-1, {
              priceListId: priceList.id,
              productId: p.product.id,
              guestCount: input.prevdayGuests,
              itemCount: input.prevdayGuests,
              date: prevDay,
            }, null, calcOptions);
            if (lineItem) mergeLineItem(result, lineItem);
          }
        }
      }
    }

    for (let dayNum = 0; dayNum < days; dayNum++) {
      const dayMom = moment(startMom).add(dayNum, 'days');
      const day = input.days.find(d => d.day === dayNum)!;

      const priceList = this.getPriceList({ date: dayMom.toDate() });

      for (const addon of day.addonProducts || []) {
        const addonProduct = addon.sku
          ? allProductsDb.find(p => p.sku === addon.sku)
          : addon.lookupCode
            ? allProductsDb.find(p => p.lookupCode === addon.lookupCode)
            : null;
        if (addonProduct) {
          addon.sku = addonProduct.sku;
          if (addonProduct.recurring === EPricing_ProductPayment.BYGUESTANDDAY || addonProduct.recurring === EPricing_ProductPayment.BYGUEST) {
            const lineItem = this.getProductLineItem(dayNum, {
              priceListId: priceList.id,
              productId: addonProduct.id,
              guestCount: day.totalGuests,
              itemCount: (addon.count || 1) * day.totalGuests,
              date: dayMom.toDate(),
            }, null, calcOptions);
            if (lineItem) mergeLineItem(result, lineItem);
          } else if (addonProduct.recurring === EPricing_ProductPayment.BYROOMANDDAY || addonProduct.recurring === EPricing_ProductPayment.BYROOM) {
            const lineItem = this.getProductLineItem(dayNum, {
              priceListId: priceList.id,
              productId: addonProduct.id,
              guestCount: day.totalGuests,
              itemCount: (addon.count || 1) * day.overnightGuests,
              date: dayMom.toDate(),
            }, null, calcOptions);
            if (lineItem) mergeLineItem(result, lineItem);
          } else if (addonProduct.recurring === EPricing_ProductPayment.BYCOUNT) {
            const lineItem = this.getProductLineItem(dayNum, {
              priceListId: priceList.id,
              productId: addonProduct.id,
              guestCount: day.totalGuests,
              itemCount: addon.count || 1,
              date: dayMom.toDate(),
            }, null, calcOptions);
            if (lineItem) mergeLineItem(result, lineItem);
          }
          // } else {
          // throw new GraphQLError(`No price found for addon product ${addon.sku || addon.lookupCode}`, { extensions: { code: 'BAD_PRICELIST', product: addon.sku || addon.lookupCode } })
        }
      }
    }

    const _filterServiceTypeFacilitiesByPayment = (recurring: string) =>
      serviceType.facilities.filter(sp => {
        const f = allFacilitiesDb.find(fd => fd.id === sp.facility.id);
        return f && f.recurring === recurring;
      });

    if (calcOptions?.addonsOnly === false || calcOptions?.addonsOnly === undefined) {
      const recurringFacilities = _filterServiceTypeFacilitiesByPayment(EPricing_FacilityPayment.BYDAY);

      for (let dayNum = 0; dayNum < days; dayNum++) {
        const day = input.days.find(d => d.day === dayNum)!;
        const isFullDay = _isFullDay(day);

        const byDayFacilities = recurringFacilities.filter(p => (isFullDay ? p.isForDay : p.isForSemiday));

        for (const f of byDayFacilities) {
          const facility = allFacilitiesDb.find(fd => fd.id === f.facility.id)!;
          const lineItem: ICalcLineItem = {
            day: dayNum,
            sku: facility.sku,
            name: facility.name,
            facility: facility,
            count: f.includedCount,
            isIncluded: true,
            priceItem: 0,
            priceGross: 0,
            priceNet: 0,
            components: [],
            taxes: [],
          };
          mergeLineItem(result, lineItem);
        }
      }

      const byCountFacilities = _filterServiceTypeFacilitiesByPayment(EPricing_FacilityPayment.BYCOUNT);
      for (const f of byCountFacilities) {
        const facility = allFacilitiesDb.find(fd => fd.id === f.facility.id)!;
        const lineItem: ICalcLineItem = {
          day: 0,
          sku: facility.sku,
          name: facility.name,
          facility: facility,
          count: f.includedCount || 1,
          isIncluded: true,
          priceItem: 0,
          priceGross: 0,
          priceNet: 0,
          components: [],
          taxes: [],
        };
        mergeLineItem(result, lineItem);
      }

      const byGuestFacilities = _filterServiceTypeFacilitiesByPayment(EPricing_FacilityPayment.BYGUEST);

      for (const f of byGuestFacilities) {
        const firstDay = input.days.find(d => d.day === 0)!;
        const facility = allFacilitiesDb.find(fd => fd.id === f.facility.id)!;
        const lineItem: ICalcLineItem = {
          day: 0,
          sku: facility.sku,
          name: facility.name,
          facility: facility,
          count: (f.includedCount || 1) * firstDay.totalGuests,
          isIncluded: true,
          priceItem: 0,
          priceGross: 0,
          priceNet: 0,
          components: [],
          taxes: [],
        };
        mergeLineItem(result, lineItem);
      }
    }

    const seminarroomFacility = serviceType?.facilities.filter(f => f.isForDay).find(sp => {
      const f = allFacilitiesDb.find(fd => fd.id === sp.facility.id);
      return f && f.lookupCode === EPricing_FacilityLookupCode.SEMINARROOM_FULLDAY;
    });
    const seminarroomSemidayFacility = serviceType?.facilities.filter(f => f.isForSemiday).find(sp => {
      const f = allFacilitiesDb.find(fd => fd.id === sp.facility.id);
      return f && f.lookupCode === EPricing_FacilityLookupCode.SEMINARROOM_SEMIDAY;
    });
    const addonSeminarroomFacility = serviceType?.facilities.filter(f => f.includedCount === 0).find(sp => {
      const f = allFacilitiesDb.find(fd => fd.id === sp.facility.id);
      return f && f.lookupCode === EPricing_FacilityLookupCode.SEMINARROOM_FULLDAY;
    }) || seminarroomFacility;
    const addonSeminarroomSemidayFacility = serviceType?.facilities.filter(f => f.includedCount === 0).find(sp => {
      const f = allFacilitiesDb.find(fd => fd.id === sp.facility.id);
      return f && f.lookupCode === EPricing_FacilityLookupCode.SEMINARROOM_SEMIDAY;
    }) || seminarroomSemidayFacility || addonSeminarroomFacility;

    for (let dayNum = 0; dayNum < days; dayNum++) {
      const dayMom = moment(startMom).add(dayNum, 'days');
      const day = input.days.find(d => d.day === dayNum)!;

      const priceList = this.getPriceList({ date: dayMom.toDate() });

      const addonFacilities = [...(day.addonFacilities || [])];

      if (day.occupancy.length > 0) {
        const bookedFullCount = day.occupancy.filter(o => o === EWL_OfferRoomOccupancy.FULLDAY).length;
        const includedFullCount = (seminarroomFacility && seminarroomFacility.includedCount) || 0;
        const addonFullCount = Math.max(0, bookedFullCount - includedFullCount);
        if (addonSeminarroomFacility && addonFullCount > 0) {
          addonFacilities.push({
            sku: allFacilitiesDb.find(fd => fd.id === addonSeminarroomFacility.facility.id)!.sku,
            count: addonFullCount,
          });
        }
        const remainingFullCountIncluded = Math.max(0, includedFullCount - bookedFullCount);

        const bookedSemiCount = day.occupancy.filter(o => o !== EWL_OfferRoomOccupancy.FULLDAY).length;
        const includedSemiCount = (seminarroomSemidayFacility && seminarroomSemidayFacility.includedCount) || 0;
        const addonSemiCount = Math.max(0, bookedSemiCount - includedSemiCount - remainingFullCountIncluded);
        if (addonSeminarroomSemidayFacility && addonSemiCount > 0) {
          addonFacilities.push({
            sku: allFacilitiesDb.find(fd => fd.id === addonSeminarroomSemidayFacility.facility.id)!.sku,
            count: addonSemiCount,
          });
        }
      }
      for (const addon of addonFacilities) {
        const addonFacility = addon.sku
          ? allFacilitiesDb.find(f => f.sku === addon.sku)
          : addon.lookupCode
            ? allFacilitiesDb.find(f => f.lookupCode === addon.lookupCode)
            : null;
        if (addonFacility) {
          addon.sku = addonFacility.sku;
          if (addonFacility.recurring === EPricing_FacilityPayment.BYGUEST) {
            const lineItem = this.getFacilityLineItem(dayNum, {
              priceListId: priceList.id,
              facilityId: addonFacility.id,
              guestCount: day.totalGuests,
              itemCount: (addon.count || 1) * day.totalGuests,
              date: dayMom.toDate(),
            }, null, calcOptions);
            if (lineItem) mergeLineItem(result, lineItem);
          } else if (addonFacility.recurring === EPricing_FacilityPayment.BYDAY || addonFacility.recurring === EPricing_FacilityPayment.BYCOUNT) {
            const lineItem = this.getFacilityLineItem(dayNum, {
              priceListId: priceList.id,
              facilityId: addonFacility.id,
              guestCount: day.totalGuests,
              itemCount: addon.count || 1,
              date: dayMom.toDate(),
            }, null, calcOptions);
            if (lineItem) mergeLineItem(result, lineItem);
          }
          // } else {
          // throw new GraphQLError(`No price found for addon facility ${addon.sku || addon.lookupCode}`, { extensions: { code: 'BAD_PRICELIST', facility: addon.sku || addon.lookupCode } })
        }
      }
    }
    sumTotalPrices(result);
    result.lineItems.sort((a, b) => (a.day === null ? -1 : b.day === null ? 1 : a.day - b.day));
    return result;
  }
}
